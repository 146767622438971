import React from 'react';
import axios from 'axios';

import AOS from "aos";
import "aos/dist/aos.css";

import Navbar from './NavBar';
import { useGlobalContext } from '../context';
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';

import { FaTimes, FaQuoteRight, FaQuoteLeft  } from "react-icons/fa"


import remote1 from "./videos/remote1.mp4"
import remote2 from "./videos/remote2.mp4"
import remote3 from "./videos/remote3.mp4"

import culture1 from "./videos/culture1.mp4"

import transcription1 from "./videos/transcription1.mp4"

import web1 from "./videos/web1.mp4"
import web2 from "./videos/web2.mp4"

import design1 from "./videos/design1.mp4"

import adobe1 from "./videos/adobe1.mp4"

import virtual1 from "./videos/virtual1.mp4"
import virtual2 from "./videos/virtual2.mp4"
import virtual3 from "./videos/virtual3.mp4"

import blog1 from "./videos/blog1.mp4"
import blog2 from "./videos/blog2.mp4"

import tutor1 from "./videos/tutor1.mp4"
import tutor2 from "./videos/tutor2.mp4"
import tutor3 from "./videos/tutor3.mp4"

import write1 from "./videos/write1.mp4"
import write2 from "./videos/write2.mp4"


export default function Videomain() {
  const { completedCD, setcompCD} = useGlobalContext();

  const [isOpen, setOpen] = React.useState(false)
  

  let candidate = completedCD[0].username;
  let candEmail = ["response@train.opportunitieshub.in", completedCD[0].email];
  let paidStatus = completedCD[0].paid;

  function Closenav() {
    setOpen(true)

    // axios.get("http://localhost:8081/clientEnquiry", {
    axios.get("https://storage.opportunitieshub.in/clientEnquiry", {

      params: {
        candidate,
        candEmail,
        paidStatus
      },
    })
    .then(() => {
      // console.log("success");
    })
    .catch(() => {
      // console.log("email not sent");
    })
  }
 
  function Closewindow() {
    setOpen(false)
  }

  return (
    <main className='training'>
      <Navbar/>
      <div className='tr-div'>
        <h2 data-aos="zoom-in-up">Training videos</h2>
        <div className='vidz-underscore' data-aos="zoom-in-up"></div>
        <p>Please ensure that you watch the training videos that apply to your specific job to the end in order to understand what is expected of you by the Canadian business partners.</p>
        <aside className='tr-aside'>

          <div className='tr-vidz'>
            <h5>1.	These videos are a beginners guide to remote working from home:</h5>
            <div className='the-vidz'>
            <video controls controlsList="nodownload" src={remote1} width="300px" height="200px"></video>
            <video controls controlsList="nodownload" src={remote2} width="300px" height="200px"></video>
            <video controls controlsList="nodownload" src={remote3} width="300px" height="200px"></video>
            </div>
          </div>
          <section className='boda'></section>

          <div className='tr-vidz'>
            <h5>2.	This video covers the Canadian workplace culture.</h5>
            <div className='the-vidz'>
            <video controls controlsList="nodownload" src={culture1} width="300px" height="200px"></video>
            
            </div>
          </div>
          <section className='boda'></section>

          <div className='tr-vidz'>
            <h5>3.	This video discuses on how to be a good transcriptionist:</h5>
            <div className='the-vidz'>
            <video controls controlsList="nodownload" src={transcription1} width="300px" height="200px"></video>
            
            </div>
          </div>
          <section className='boda'></section>

          <div className='tr-vidz'>
            <h5>4.	These videos illustrate how to become a good web designer:</h5>
            <div className='the-vidz'>
            <video controls controlsList="nodownload" src={web1} width="300px" height="200px"></video>
            <video controls controlsList="nodownload" src={web2} width="300px" height="200px"></video>

            
            </div>
          </div>
          <section className='boda'></section>

          <div className='tr-vidz'>
            <h5>5.	This video offers good graphic design tips:</h5>
            <div className='the-vidz'>
            <video controls controlsList="nodownload" src={design1} width="300px" height="200px"></video>
            

            
            </div>
          </div>
          <section className='boda'></section>

          <div className='tr-vidz'>
            <h5>6.	This video illustrates how to use adobe photoshop:</h5>
            <div className='the-vidz'>
            <video controls controlsList="nodownload" src={adobe1} width="300px" height="200px"></video>
            

            
            </div>
          </div>
          <section className='boda'></section>

          <div className='tr-vidz'>
            <h5>7.	These videos cover virtual assistant essential skills:</h5>
            <div className='the-vidz'>
            <video controls controlsList="nodownload" src={virtual1} width="300px" height="200px"></video>
            <video controls controlsList="nodownload" src={virtual2} width="300px" height="200px"></video>
            <video controls controlsList="nodownload" src={virtual3} width="300px" height="200px"></video>

            
            </div>
          </div>
          <section className='boda'></section>

          <div className='tr-vidz'>
            <h5>8.	These videos cover blog article writing:</h5>
            <div className='the-vidz'>
            <video controls controlsList="nodownload" src={blog1} width="300px" height="200px"></video>
            <video controls controlsList="nodownload" src={blog2} width="300px" height="200px"></video>
            

            
            </div>
          </div>
          <section className='boda'></section>

          <div className='tr-vidz'>
            <h5>9.	These videos covers online tutoring:</h5>
            <div className='the-vidz'>
            <video controls controlsList="nodownload" src={tutor1} width="300px" height="200px"></video>
            <video controls controlsList="nodownload" src={tutor2} width="300px" height="200px"></video>
            <video controls controlsList="nodownload" src={tutor3} width="300px" height="200px"></video>
            
            </div>
          </div>
          <section className='boda'></section>

          <div className='tr-vidz'>
            <h5>10.	These videos cover copywriting skills:</h5>
            <div className='the-vidz'>
            <video controls controlsList="nodownload" src={write1} width="300px" height="200px"></video>
            <video controls controlsList="nodownload" src={write2} width="300px" height="200px"></video>
            
            </div>
          </div>
          <section className='boda'></section>

          <div className='py-btn-div'>
            <button className='py-buttons' onClick={Closenav}>COMPLETE</button>
          </div>

          {/* %%%%%%%%%%%%%%%%% FeedBack %%%%%%%%%%%%%%%%%%%% */}

          <div
      className={`${
        isOpen ? 'sidebar-wrapper show' : 'sidebar-wrapper'
      }`}
    >
      <aside className='sidebar'>
      <Link to="/">
        <button className='close-btn' onClick={Closewindow}>
          
          <FaTimes />
        </button>
      </Link>
        

        <div>
          
          <h5> <FaQuoteLeft className='fa-vid'/> Thank you for completing training, your remote work journey with us officially begins on  28th November, 2024. Our team will send you the website link to our job portal plus the login details. Our peer mentors will guide you on how to communicate with clients on the portal, how to bid for jobs and how to do the jobs and deliver them back to clients. The mentors will also guide you on how you will get paid for any task that you that you perform<FaQuoteRight className='fa-vid'/></h5>
        </div>
        
            
              
          
            
            
          
      </aside>
    </div>
          
        </aside>
      </div>

    </main>
  )
}