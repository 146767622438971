import React from 'react';
import axios from 'axios';

import { Link } from 'react-router-dom';
import Navbar from './NavBar';

import { useGlobalContext } from '../context';



export default function Home() {
    const [thecand, setcand] = React.useState("empty")
   const {regno, completedCD, setcompCD} = useGlobalContext();

   const [pam, setpam] = React.useState(false)
   const [deev, setDeev] = React.useState(false)


   React.useEffect(() => {
    const items = JSON.parse(localStorage.getItem('items'));
    if (items) {
     setcand(items);
    }
  }, []);


// @@@@@@@@@@@@@@@@@@@@@ Fetch from Database @@@@@@@@@@@@@@@@@@

   const [data, setData] = React.useState([])
   const [newArray, setArray] = React.useState([{username: "name"}])
    React.useEffect(()=> {
        axios.get("https://storage.opportunitieshub.in/college")
        // axios.get("http://localhost:8081/college")

        .then((res)=>{
            setData(res.data)
            setArray(res.data.filter((old)=> old.password == thecand))

        })
        .catch((err)=>console.log("error"))

        
    }, [thecand])


    React.useEffect(()=> {
        // console.log(newArray[0].username)
        
    }, [thecand])


    function Nextpage() {
        setDeev(true)
        // console.log(typeof newArray[0].paid)
        if(newArray[0].paid == "paid") {
            setpam(false)
        } else {
            setpam(true)
        }
    }

    

    // get email details
    function Getdetails() {
        setcompCD(newArray)
        
    }
    
    return (
      <div className='homepage'>
          <Navbar/>
          <div className='prereq'>
            <h2>Prereqiusites</h2>
            
            <div className='prereq-und'></div>
            
            <p className='prereq-p'>This training uses short videos as the training materials and it costs $11 or Ksh.1500/=. Training materials are only accessible upon payment. Candidates are expected to sign-up before making any payments. Payments are made via Mpesa and a candidate will recieve payment prompt on Mpesa phone number that he/she provides.</p> 

            <h3>Training Modules</h3>
            <aside className='the-mods'>
                <div className='mod-div push-rt'>
                    <span>1. Guide to remote working </span><br/>
                    <span>2. Canadian workplace culture</span><br/>
                    <span>3. Tips on transcription skills</span><br/>
                    <span>4. Tips on web design skills</span><br/>
                    <span>5. Tips on graphic design skills</span>

                </div>

                <div className='mod-div'>
                    <span>6. Master adobe photoshop</span><br/>
                    <span>7. Virtual assistant essential skills</span><br/>
                    <span>8. Blog article writing</span><br/>
                    <span>9. Online tutoring skills</span><br/>
                    <span>10. Copywriting skills</span>

                </div>
            </aside>

            <section className='get-started'>
            <div className='gt-stBtn'>
                <button onClick={Nextpage} className='prereq-btn'>Click to get started</button>

            </div>            
            <div className={deev ? "trial-active makeup": "trial-yy makeup"}>
                
            {pam ? newArray.map((person)=>{
                    return (
                     
                        <Link to={`/PAY/${person.id}`} className='examLink'>Make Payment</Link>
                        

                    )
                }):
                newArray.map((person)=>{
                    return (
                     
                        <Link to={`/VIDEOhome/${person.id}`} className='examLink' onClick={Getdetails}>Go to Lesson</Link>
                    )
                })
                
            } </div>
            </section>

          </div>
      </div>
  )
}