import React from 'react';
import { Link } from 'react-router-dom';

import AOS from "aos";
import "aos/dist/aos.css";

import Navbar from './NavBar';

export default function Intro() {
  React.useEffect(()=> {
    AOS.init({
      offset: 100,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, [])
  const [menuOpen, setMenu] = React.useState(false) 

  function ChangeMenu() {
    setMenu(!menuOpen)
    
  }
  return (
    <section className='Regsection'> 
    <Navbar/>
      

      <main className='welcome-TC'>
        <div>
          <h2 className='welc-h2' data-aos="zoom-in-up">Welcome to Opportunity Hub Training Course</h2>
        
          <aside data-aos="fade-up"
     data-aos-duration="3000">
            <h4>This training should be completed within 72 hours and covers the following areas:</h4>
            <div className='cours-TC'>
              <p>1. Canadian workplace culture.</p>
              <p>2. Guide on  how to work from home comfortably.</p>
              <p>3. Short courses training on:</p>
              <span>a. Transcription skills</span><br/>
              
              <span>b. Blog article writing skills</span><br/>
              <span>c. Online tutoring skills</span><br/>
              <span>d. Copywriting skills</span><br/>
              <span>e. Graphic design skills</span><br/>
              <span>f. Web development skills</span><br/>
              <span>g. Virtual assistant skills</span>
            </div>
          </aside>
        </div>
        <Link to="/login" className='link-register'>Continue</Link>
      </main>
    </section>
  )
}