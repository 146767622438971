import React from 'react';
import axios from 'axios';

import Navbar from './NavBar';


import { useGlobalContext } from '../context';
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';


export default function Money() {

  const [ert, setErt] = React.useState(false);
  const [oppj, setOppj] = React.useState(true)
  
  const [data, setData] = React.useState([{
    email: "",
    name:"",
    paid: ""

  }])

  // @@@@@@@@@@@@@ MPESA @@@@@@@@@@@@@@@@

  const [phone, setPhone] = React.useState('');
  
  const [amount, setAmount] = React.useState('');
  const [message, setMessage] = React.useState('');

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  

  // const handleAmountChange = (e) => {
  //   setAmount(e.target.value);
  // };
  const {id} = useParams();
  const navigate = useNavigate();

  React.useEffect(()=> {

    axios.get(`https://storage.opportunitieshub.in/college_std/${id}`)
    // axios.get(`http://localhost:8081/college_std/${id}`)

    .then((res)=>{
        setData(res.data)

    })
    .catch((err)=>console.log("error"))

  }, [id])

  // ##################################

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch("https://storage.opportunitieshub.in/api/stkpush", {
      // fetch("http://localhost:8081/api/stkpush", {

    

      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ phone: phone }),
    })
      .then((response) => response.json())
      .then((data) => {
        
        setMessage('Payment successful!');
        document.querySelector(".paid-yes").click()
      })


      .catch((error) => {
        
        setMessage('Payment failed!');
      });
  };



  // @@@@@@@@@@@@ end of MPESA @@@@@@@@@@



  



  // #########################
  function Updatepaid() {
    setData([{...data[0], paid: "paid"}])

  }
  function Submit4m() {
    
  // setData([{...data[0], paid: "yes"}])
  setErt(true)
  setOppj(false)
  
  }


  // #################################
  function Submit() {
    axios.put('https://storage.opportunitieshub.in/score/'+ id, data[0])
    // axios.put('http://localhost:8081/score/'+ id, data[0])

      .then((res)=> {
        //  navigate("/home")
         
      })
      .catch(err=>console.log("error"))
    
  }

  // @@@@@@@@@@@  Sending email @@@@@@@@@@@@@

  return (
    <main>
      <Navbar/>
      <section className='mpesa-sec'>
      <h4>A payment prompt will be sent to your phone number requesting for your pin. After making payment, click the COMPLETE button to continue. Thank you! </h4>
  

  {/* @@@@@@@@@@@ MPESA @@@@@@@@@@@@@ */}

    <form onSubmit={handleSubmit} style={styles.form}>
      <h3>Mpesa Payment</h3>

      {message && <p style={{ color: message.includes('failed') ? 'red' : 'green' }}>{message}</p>}
      <div style={styles.formGroup}>
        <label htmlFor="phone" style={styles.label}>Phone Number</label>
        <input
          type="number"
          id="phone"
          value={phone}
          placeholder='07XXXXXXXX'
          onChange={handlePhoneChange}
          style={styles.input}
          required
        />
      </div>
      
      <button type="submit" className='pay-btn'>Pay Ksh.1500</button>


    </form>

    <button className='paid-yes' onClick={Updatepaid}>Update-Paid</button>

    {oppj && 
    
      <div className='py-btn-div'>
        <button className="py-buttons" type="submit" onClick={Submit4m} >Complete</button>
      </div>
    }

    

    {ert && 
    
      <div className='py-btn-div'>
        <Link to="/login">
          <button onClick={Submit} className="py-buttons">Login</button>
        </Link>
      </div>
    }
    

      </section>
    

    </main>
  )
}

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    borderRadius: '8px',
    backgroundColor: '#f0f0f0',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Added box shadow
  },
  formGroup: {
    marginBottom: '1rem',
  },
  label: {
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
  input: {
    padding: '0.5rem',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '1rem',
    width: '95%',
  },
  
  
};