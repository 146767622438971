import React from 'react';

import Tlogo from "./photos/logo.jpeg"
import { Link, useNavigate } from 'react-router-dom'
import {FaAlignRight, FaTimes} from "react-icons/fa"


export default function Navbar() {
  const [sidenav, setNav] = React.useState(false)

  function Shownav() {
    setNav(true)
  }
  function closenav() {
    setNav(false)
  }
  return (
    <main className='navbarMain'>
      <div className='navLogo'>
        <img src={Tlogo} className='Tlogo'/>
        <span>Opportunity Hub</span>
      </div>

      <div className='FaDiv'><span onClick={Shownav}><FaAlignRight/></span></div>

      <div className={sidenav ?"nav-show nav-links": "nav-links"}>
        <ul>
          <span className='close-nav' onClick={closenav}><FaTimes/></span>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/login">Courses</Link></li>
          <li><Link to="/login">Resources</Link></li>

        </ul>
      </div>
    </main>
  )
}