import react from "react";

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Signup from "./signup";
import Login from "./login";
import Home from "./home";


import Intro from "./MAINpage";
import Money from "./PAY";
import Videomain from "./VIDEOhome";


export default function App() {
    return (
        
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Intro/>}></Route>
                    <Route path="/signup" element={<Signup/>}></Route>
                    <Route path="/home" element={<Home/>}></Route>
                    <Route path="/login" element={<Login/>}></Route>
                   
                    
                    <Route path="/PAY/:id" element={<Money/>}></Route>
                    <Route path="/VIDEOhome/:id" element={<Videomain/>}></Route>



                    {/* <Route path="/signup" element={<Signin/>}/> */}


                </Routes>
            </BrowserRouter>
        </>
    )
}