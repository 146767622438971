import React from 'react';
import { useContext } from 'react';

const AppContext = React.createContext();

export const AppProvider = ({children}) => {
  const [regno, setRegno] = React.useState([0]);
  const [completedCD, setcompCD] = React.useState("");


  return (
    <AppContext.Provider value={{regno, setRegno, completedCD, setcompCD}}>
  {children}
</AppContext.Provider>
  )
} 

export const useGlobalContext = ()=> {
  return useContext(AppContext);
}

