import React from 'react';
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { useGlobalContext } from '../context';

import Navbar from './NavBar';


export default function Login() {
    const {regno, setRegno} = useGlobalContext();
    const [values, setValues] = React.useState(
        {
            username: "",
            password: ""
        }
    )

    const navigate = useNavigate();

    function HandleInput(event) {
        setValues(prev => (
            {
                ...prev,
                [event.target.name]:[event.target.value]
            }
        ))
    }

    function HandleSubmit(event) {
        event.preventDefault();
        
        axios.post("https://storage.opportunitieshub.in/login", values)
        // axios.post("http://localhost:8081/login", values)
        .then((res)=> {
            if (res.data === "Success") {
                navigate("/home");
            } else {
                alert("No record existed")
            }
        })
        .catch(err=>console.log("error"))
        setRegno(values.password)
    }

    React.useEffect(() => {
        localStorage.setItem('items', JSON.stringify(values.password[0]));
      }, [values]);

    return (
        <section>
            <Navbar/>
            <main className='login-main'>
            <div className='login-div'>
                <h3>--login page--</h3>
                <form action='' onSubmit={HandleSubmit}>
                
                    <div className='inp-divs'>
                        {/* <label htmlFor='regno'>Reg No</label> */}
                        <span className='align-left'>Username</span><br/>
                        <input className="all-inputs" type='text' name='username' onChange={HandleInput} placeholder="enter username" required/>
                    </div>
                    <div className='inp-divs'>
                        {/* <label htmlFor='password'>Password</label> */}
                        <span className='align-left'>Password</span><br/>
                        <input className="all-inputs" type='password' onChange={HandleInput} name='password' placeholder="enter password" required/>
                    </div>
                <button className='login-btn' type='submit'>Login</button> <br/>
                <p className='or'>OR</p>
                <Link to="/signup" className='create-account'>Create account</Link>

                </form>
            </div>
            
            
        </main>
        </section>
    )
}