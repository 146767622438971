import axios from 'axios';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Navbar from './NavBar';
// import Validation from './FormValidation';

import { useGlobalContext } from '../context';


export default function Signup() {
    const {regno, setRegno} = useGlobalContext();


    // const [errors, setErrors] = React.useState({})
    const [values, setValues] = React.useState(
        {
            username: "",
            email: "",
            password: "",
            paid: "no"
        }
    )

    const navigate = useNavigate();



    function HandleInput(event) {
        setValues(prev => (
            {
                ...prev,
                [event.target.name]:[event.target.value]
            }
        ))
    }

    function HandleSubmit(event) {
        event.preventDefault();
        
        axios.post("https://storage.opportunitieshub.in/college", values)
        // axios.post("http://localhost:8081/college", values)
        .then((res)=> {
            navigate("/home")
        })
        .catch(err=>console.log("error"))
    }
    // setRegno(values.password)
    
    React.useEffect(() => {
        localStorage.setItem('items', JSON.stringify(values.password[0]));
        
      }, [values]);
    return (
        <section>
            <Navbar/>
            <main className='login-main'>
            <div className='signin-div'>
                <h3>--Sign Up Page--</h3>
                <form action='' onSubmit={HandleSubmit}>
                    <div className='inp-divs'>
                        {/* <label>Name</label> */}
                        <span className='align-left'>Username</span><br/>
                        <input className="all-inputs" type='text' onChange={HandleInput} name='username' placeholder='enter name e.g John'/>
                        
                    </div>
                    
                    
                    <div className='inp-divs'>
                        {/* <label htmlFor='email'>Email</label> */}
                        <span className='align-left'>Email</span><br/>
                        <input className="all-inputs" type='email' onChange={HandleInput} name='email' placeholder="enter a valid email" required/>
                        
                    </div>
                    <div className='inp-divs'>
                        {/* <label htmlFor='email'>Email</label> */}
                        <span className='align-left'>Password</span><br/>
                        <input className="all-inputs" type='password' onChange={HandleInput} name='password' placeholder="enter password" required/>
                        
                    </div>
                    
                    
                    <button className='login-btn' type='submit'>Sign Up</button> <br/>
                    <p className='or'>OR</p>
                    <Link to="/login" className='create-account'>Login</Link>
                    
                </form>
            </div>
            
            
        </main>
        </section>
    )
}